//import { Link } from "gatsby"
import React from "react"
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "gatsby"

import Page from "../components/page"

export default function Index() {
	return (
		<>
			<Page title="">
				<Container fluid="lg">
					<Col className="col-lg-12 min-vh-100">
						<Alert variant="info">
							Bitte nutzen Sie Ihren direkten Streamlink, inklusive der Wörter hinter der Domain. 
							Zum Beispiel: <code> https://streaming.splvs.net/prinz-merkte-drell </code>.
						</Alert>
						You can either&nbsp;
						<Link to="/example">
							visit sample embed
						</Link>
						&nbsp;or, if you prefer,&nbsp;
						<Link to="/kittens">
							visit sample kitten stream
						</Link>.
					</Col>
				</Container>
			</Page>
		</>
	);
}
